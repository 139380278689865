<template>
    <PageNav :page-name="task ? task.name : ''" only-breadcrumb/>
    <CCard>
        <CCardBody>
            <div class="taskFinish">
                <h2 class="taskFinish__title">Thank you</h2>
                <p class="taskFinish__text">
                    You successfully signed the document
                    <strong :class="{ clickable: !isPendingStatus }" @click.prevent="handleDocumentNameClicked">
                        {{ contract?.name }}
                    </strong>
                </p>
                <p v-if="isClosedStatus" class="taskFinish__text">
                    You can download a copy
                    <strong><span class="text-decoration-underline clickable" @click="download">here</span></strong>
                    or from the task
                    <strong class="clickable" @click.prevent="handleTaskNameClicked">{{ task?.name }}</strong>
                </p>
                <p v-if="isPendingStatus" class="taskFinish__text">
                    You will be notified when
                    <strong>{{ contract?.sending_signer?.company?.name }}</strong>
                    signs the document and be able to download a copy
                </p>

                <div class="taskFinish__cards"
                     :style="parseInt(contract?.receiving_signer.id) === parseInt(authUser.id) ? '' : 'flex-direction: row-reverse;'">
                    <div class="taskFinish__card">
                        <div class="status"></div>
                        <div class="taskFinish__card__item">
                            <img src="@/assets/images/note.png"/>
                            <div class="taskFinish__card__item-info">
                                <div class="taskFinish__card__item-name">
                                    {{ contract?.receiving_signer?.company?.name }}
                                </div>
                                <div class="taskFinish__card__item-type">
                                    <span class="text-dark">COMPLETED</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="taskFinish__card">
                        <div class="taskFinish__card__item" :class="{ pending: isPendingStatus }">
                            <img src="@/assets/images/note.png"/>
                            <div class="taskFinish__card__item-info">
                                <div class="taskFinish__card__item-name">
                                    {{ contract?.sending_signer?.company?.name }}
                                </div>
                                <div class="taskFinish__card__item-type" :class="{ pending: isPendingStatus }">
                                    {{ isPendingStatus ? 'PENDING' : 'COMPLETED' }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="taskFinish__link">
                    <router-link :to="{ name: 'ProjectDashboard', params: { id: $route.params.id } }">
                        {{ project?.name }}
                    </router-link>
                </div>
            </div>
        </CCardBody>
    </CCard>
</template>

<script>
import {downloadFile} from '@/utils/files'
import {FULLY_EXECUTED, PENDING_INTERNAL_SIGNATURE} from "@/domain/Entities/Task/taskStatuses";

export default {
    data: () => ({
        task: null,
        contract: null,
        project: null,
    }),
    computed: {
        isPendingStatus() {
            return this.task?.status === PENDING_INTERNAL_SIGNATURE
        },
        isClosedStatus() {
            return this.task?.status === FULLY_EXECUTED
        },
    },
    created() {
        this.fetchData()
    },
    methods: {
        handleDocumentNameClicked() {
            this.$router.push({
                name: 'TaskContractsReviewDocument',
                params: {
                    id: this.$route.params.id,
                    task_id: this.$route.params.task_id,
                },
            })
        },
        handleTaskNameClicked() {
            this.$router.push({
                name: 'TaskContracts',
                params: {
                    id: this.$route.params.id,
                    task_id: this.$route.params.task_id,
                },
            })
        },
        download() {
            this.$http.common
                .downloadSingleDocument({
                    type: 'task',
                    task_id: this.$route.params.task_id,
                    content_type: 'content',
                })
                .then((response) => {
                    downloadFile(response, this.task.contract.name + '.pdf')
                })
        },
        fetchData() {
            let {task_id, id} = this.$route.params
            if (task_id) {
                this.$http.tasks
                    .getTask({params: {task_id: task_id}})
                    .then((res) => {
                        this.task = res.data.data
                        this.contract = this.task.contract
                    })
            }
            if (id) {
                this.$http.projects
                    .fetchProjects({
                        params: {
                            per_page: -1,
                            page: 1,
                            sort_field: '',
                            sort_direction: '',
                        },
                    })
                    .then(({data}) => {
                        this.project = data.data?.find((el) => el.id === parseInt(id))
                    })
            }
        },
    },
}
</script>
<style lang="scss">
.taskFinish {
    padding: 50px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__title {
        font-weight: 700;
        font-size: 28px;
        margin: 0 0 20px;
        text-align: center;
    }

    &__text {
        font-weight: 400;
        font-size: 16px;
        margin: 0 0 12px;
        text-align: center;
    }

    &__link {
        text-decoration: underline;
        font-weight: 400;
        font-size: 16px;
        color: #303c54;
        margin-top: 100px;
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 16px;
        color: #303c54;

        &s {
            //display: flex;
            //align-items: center;
            //justify-content: center;
            //flex-wrap: wrap;
        }

        &__item {
            display: flex;
            align-items: center;
            border: 1px solid #b1b7c1;
            border-radius: 8px;
            margin: 30px 15px 15px;
            padding: 20px 50px;
            width: 500px;

            &.pending {
                background-color: lightgrey;
            }

            img {
                width: 34px;
                height: 30px;
                object-fit: contain;
                margin-right: 25px;
            }

            &-info {
            }

            &-name {
                font-weight: 500;
                font-size: 16px;
            }

            &-type {
                font-weight: 400;
                font-size: 14px;
                color: #b1b7c1;

                &.pending {
                    color: #303c54;
                }
            }
        }
    }
}
</style>
